import React, { useState, Fragment } from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout' 
import Hero from '../components/widgets/Hero' 
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Widgets from '../components/widgets/Widgets' 
import formatPhoneNumber from '../utils/formatPhoneNumber' 
import Seo from 'gatsby-plugin-wpgraphql-seo';
import InternalShapes from "../components/widgets/InternalShapes";
import AdditionalDownloads from "../components/widgets/AdditionalDownloads";
import LinkedInImg from "../assets/svg/inline/ic_linkedin.svg"

export default function FindABDM({ data }) {
  const page = data.allWpPage.nodes[0];
  const bdmAreas = data.allWpBdmArea?.nodes.sort( (a,b) => a.position-b.position );//sort the areas by position
  const additionalDownloads = page.additionalDownloads;

  //for controlling form elements
  const defaultFormValues = {search:'', area:''}
  const [formValues, setFormValues] = useState(defaultFormValues);

  /**
   * Called when a form field changes
   */
  const handleChange = function(event) {

    var newValues = { ...formValues };

	newValues[event.target.name] = event.target.value;
	
	setFormValues(newValues);
  }

  /*
   * When the form is submitted via the enter key
   */
  const handleSubmit = function(event) {
    event.preventDefault();
	//submitForm()
  }

  /*
   * When we select an area from the list on desktop
   */
  const selectArea = function(area) {
    
    var newValues = { ...formValues };

	if (formValues.area===area) {
		newValues['area'] = '';
	} else {
		newValues['area'] = area;
	}
	
	setFormValues(newValues);
  }

  /*
   * When we select an area from the list on desktop
   */
  const getFilteredBDMs = function(bdms, lastArea) {
	if (!bdms || bdms.length===0) {
		return null;
	}
	
	//sort the bdms according to menu order
	bdms.sort(function(a, b) {
    	return a.menuOrder - b.menuOrder;
	});
	
    //check if we have a postcode search set
    if (formValues.search!=='') {
		//extract the leading letters from the user postcode using regular expression
		//console.log(formValues.search.toUpperCase().match(/^[A-Z]+/));
		var match = formValues.search.toUpperCase().match(/^[A-Z]+/);
		if (match) {
			//filter the BDMS
			var newBdms = [];
			bdms.forEach(function(bdm) {
				if (bdm.bdm.bdmAreas?.toUpperCase().split(',').includes(match[0])) {
					newBdms.push(bdm);
				}
			});
			if (newBdms.length===0) {
				return null;
			}
			bdms = newBdms;
		}
    }
	return bdms.map((bdm, j) => (
	  <Fragment key={`bdm${j}`}>
		{
			lastArea && j===(bdms.length-1) && bdms.length>1
			? <InternalShapes page="bdm-finder" widget="Bdm" />
			: null
		}								
		<Bdm bdm={bdm} />
	  </Fragment>							    		
	))
  }

  const Bdm = ({bdm}) => (
    <div className="bdm">
		<div className="img">
			{bdm.featuredImage?.node && (
				<GatsbyImage image={getImage(bdm.featuredImage.node.localFile)} alt={bdm.title} />
			)}
		</div>	
		<div className="copy">
			<h3>{bdm.title}</h3>
			<p className="job-title">{bdm.bdm.bdmJobTitle}</p>
			{bdm.bdm.bdmAreas && !bdm.bdm.bdmHideAreas && (
				<p className="areas">
					<span className="label">Areas covered</span>
					<span className="areas-list">
					  {bdm.bdm.bdmAreas.split(',').map((area, k) => (
						<span key={`area${k}`} className="area">{area}</span>
				      ))}
				 	</span>
			    </p>
			)}											
			{
			  bdm.bdm.bdmBio 
			  ? <p className="bio" dangerouslySetInnerHTML={{ __html: bdm.bdm.bdmBio }} />	
			  : null								  
			}										
			<div className="contacts">
				{bdm.bdm.bdmPhone && (
				  <p className="phone">Call <a href={formatPhoneNumber(bdm.bdm.bdmPhone)}>{bdm.bdm.bdmPhone}</a></p>
			    )}
				{bdm.bdm.bdmEmail && (
				  <p className="email">Email <a href={`mailto:${bdm.bdm.bdmEmail}`}>{bdm.bdm.bdmEmail}</a></p>
			    )}
				{
				  bdm.bdm.bdmLinkedin
				  ? <a href={bdm.bdm.bdmLinkedin} target="_blank" rel="noreferrer" aria-label="LinkedIn" className="linkedin"><LinkedInImg /></a>
				  : null
				}
			</div>	
		</div>	
	</div>
  );

  return (
    <Layout className="bdm">
      <Seo post={page} />
	  <article>
	    <header className="page-widgets">
	      <Hero widget={{layout : 'inner', title: page.title, content: page.content}} />
	    </header>
		{
			bdmAreas.length===0
			? <div className="internal"><p>No BDMs found</p></div>
			: <div className="internal columns">
				<aside>
					<form onSubmit={handleSubmit}>
						<p>
							<span className="search-wrap">
								<input type="text" name="search" autoComplete="off" placeholder="Search by postcode" value={formValues.search} onChange={handleChange} />
								<button type="submit" className="plain" aria-label="Submit search"></button>
							</span>
						</p>
						<p>
							<select name="area" value={formValues.area} onChange={handleChange}>
								<option value="">Select by area</option>
							    {bdmAreas.map((area, i) => (
								    <option value={area.name} key={`area${i}`}>{area.name}</option>
							    ))}
							</select>
						</p>
						<ul>
						    {bdmAreas.map((area, i) => (
							    <li key={`area${i}`} className={formValues.area===area.name ? 'active' : ''}><button type="button" className="plain" onClick={() => selectArea(area.name)}>{area.name}</button></li>
						    ))}
						</ul>
					</form>
				</aside>
				
		    	<div className="bdms">
				    {bdmAreas.map((area, i) => {
						//are the areas being filtered?
						if (formValues.area===area.name || formValues.area==='') {
							let filteredBDMs = getFilteredBDMs(area?.bdms?.nodes, (i===(bdmAreas.length-1)) );
							if (filteredBDMs) {
							  return(
								<section className="area" key={`area${i}`}>
									<h2>{area.name}</h2>
									{filteredBDMs}						    		
							    </section>
							  )
							}
							return null;
						}	
						return null;
				    })}
			
					<AdditionalDownloads title={additionalDownloads.downloadsTitle} downloads={additionalDownloads.downloads} />
				</div>
			</div>
		}	    
		
		<Widgets widgets={page.widgets?.pageWidgets} />
		
	  </article>
    </Layout>
  )
}
export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
		slug
        content
		...WidgetFragment
        ...PageSEOFragment
	    additionalDownloads {
		  downloadsTitle
	      downloads {
	        download
	      }
	    }
      }
    }
    allWpBdmArea {
      nodes {
        name
		position
        bdms {
          nodes {
	 		menuOrder
            title
            bdm {
              bdmJobTitle
			  bdmAreas
			  bdmHideAreas
              bdmBio
              bdmEmail
              bdmPhone
              bdmLinkedin
            }
            featuredImage {
              node {
                localFile {
			      childImageSharp {
		             gatsbyImageData(
					  sizes: "(min-width: 768px) 187px, 72px",
					  breakpoints: [144, 374]
		             )
		           }
                }
              }
            }
          }
        }
      }
    }
  }
`